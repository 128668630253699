import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { Layout } from "../components/common"
import { Button } from "react-bootstrap"
import { Link } from "gatsby"
import ContactUsBtn from "../components/common/ContactUsBtn";

let videoBG = require('../images/page-images/bridge.jpg')

class BridgeAndInfrastructure extends Component {
    render() {
        return (
            <Layout>

                <div className="container">
                    <h1>
                        Bridge and Infrastructure Inspection
                    </h1>
                    <div className="row">
                        <div className="col-md-6">
                            <img className={'mbl'} src={videoBG} alt=""/>
                        </div>
                        <div className="col-md-6">
                            <p>
                                The use of drone aerial photography and video has been recognized as a remarkable revolution in the inspection of bridges and other infrastructure. Drones can save time, deliver efficiency and cut costs. The use of the technology provides a number of essential improvements over the traditional methods of bridge and infrastructure inspection methods.
                            </p>

                            <p>
                                Drone aerial photography and video for the process of bridge inspection could actually be a valuable game changer for the industry. The use of the technology puts the workforce at risk, as they do not need to climb high, risky and hard-to-reach areas and infrastructure. Drones also make it easier for the workers and engineers to see the bridges’ hardest-to-reach sections at any time with little to no preparation or notice in advance requires.
                            </p>

                            <ContactUsBtn/>
                        </div>
                    </div>

                </div>
            </Layout>
        )
    }
}

BridgeAndInfrastructure.propTypes = {}

export default BridgeAndInfrastructure
